import { create } from '@blabu.com/timesync'
import getConfig from 'next/config'
import React, { useEffect } from 'react'

import getLogger from '@src/Logger'
import { getItemTtl, setItemTtl } from '@helpers/localStorage'
import { minutes } from '@helpers/interval'

const { TIME_SYNC_HOST } = getConfig().publicRuntimeConfig
export const SYNC_INTERVAL = minutes(5)
const LOCAL_STORAGE_KEY = 'timeSync'
let ts = null

export const getTimeSync = () => {
  if (!ts) {
    ts = create({
      server: TIME_SYNC_HOST,
      // Do not start automatic synchronization when TimeSync instance is created.
      // Thus instance is immediately available wherever getter is called
      // but sync process timing is controlled by WithSync component.
      interval: null,
    })
    ts.on('error', handleError)

    // Init with previously computed offset if any
    const persisted = getItemTtl(LOCAL_STORAGE_KEY)
    if (persisted) {
      ts.offset = persisted.offset
      ts.emit('change', persisted.offset)
    }
  }

  return ts
}

const handleChange = offsetInMiliseconds => {
  setItemTtl(LOCAL_STORAGE_KEY, { offset: offsetInMiliseconds })
  getLogger().info({ obj: { offsetInMiliseconds } }, 'TimeSync offset changed')
}

const handleError = err => {
  getLogger().warn({ err }, 'TimeSync error')
}

const startSync = () => {
  const sync = () => {
    ts.sync().catch(err => handleError(err))
  }
  // start an interval to automatically run a synchronization once per interval
  ts._timeout = setInterval(sync, SYNC_INTERVAL)

  // synchronize immediately on the next tick (allows to attach event
  // handlers before the timesync starts).
  setTimeout(sync, 0)
}

const stopSync = () => {
  clearTimeout(ts._timeout)
  ts.destroy()
}

export const now = (): number => {
  return ts ? ts.now() : Date.now()
}

const withTimeSync = () => App => {
  const WithTimeSync = props => {
    useEffect(() => {
      const timeSync = getTimeSync()
      timeSync.on('change', handleChange)
      startSync()

      return () => {
        timeSync.off('change', handleChange)
        stopSync()
      }
    }, [])

    return <App {...props} />
  }

  if (App.getInitialProps) {
    WithTimeSync.getInitialProps = App.getInitialProps
  }

  return WithTimeSync
}

export default withTimeSync
